import { template as template_b2cb0fc9fcbf44feba9a9d870a28205a } from "@ember/template-compiler";
const WelcomeHeader = template_b2cb0fc9fcbf44feba9a9d870a28205a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
