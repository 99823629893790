import { template as template_80a4887d25fa4d068e692cd49e6cdd4f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_80a4887d25fa4d068e692cd49e6cdd4f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
